.Form {
  width: 100%;
  min-width: 500px;
}

.Input {
  margin: 0;
}

.FileInput {
  max-width: 320px;
}

.InfoText {
  margin: 0;
  font-size: 14px;
}

.InfoLink {
  display: inline-block;
  margin-top: 8px;
}
