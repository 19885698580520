.Root {
  padding: 0;
}

.Actions {
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}

.ActionBtn {
  margin-right: 10px;
}

.Filter {
  display: inline-block;
  float: right;
}

.FilterInput {
  width: 300px;
  margin: 0 20px 0 0;
  vertical-align: middle;
}

.FilterClearButton {
  width: 100px;
}
