.Root {
  padding: 10px;
}

.StatusPrefix {
  margin-right: 4px;
}

.ResultBlock {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.ResultPrefix {
  margin-bottom: 0;
}

.ResultCodeBlock {
  background-color: #383838;
  padding: 10px;
}

.ResultCode {
  display: block;
  color: white;
}
