.Root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.Form {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  flex: 1;
}

.Input {
  width: 60%;
  min-width: 200px;
}

.ActionBtnGroup {
  margin-top: auto;
  text-align: right;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Hidden {
  display: none;
}
