.CertificateInfo {
  padding: 20px;
}

.CertificateFieldTitle {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 4px;
}

.CertificateFieldValueEmpty {
  opacity: 65%;
}

.CertificateDeviceLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}