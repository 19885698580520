.Root {
  height: 100%;
  padding: 20px;
}

.Filter {
  width: 100%;
  padding: 10px;
}

.FilterInput {
  width: 300px;
  margin: 0 20px 0 0;
  vertical-align: middle;
}

.FilterClearButton {
  width: 100px;
}
