.Root {
  background-color: #333;
  color: white;
  height: 50vh;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;
}

.InputContainer {
  display: flex;
}

.InputPrefix {
  margin-right: 6px;
}

.Input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  color: white;
}

.Terminal {
  white-space: pre-line;
}

.Spinner {
  margin-right: 8px;
}
.Link {
  width: 18px;
  margin-right: 6px;
}
