.SmartGroupsContainer {
  flex: 1;
  position: relative;
  z-index: 0;
}

.Actions {
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}
