.DeviceName {
  color: black;
  margin: 0 30px 0 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FooterButtons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.NavLink {
  padding-left: 10px;
  padding-right: 12px;
  color: black;

  &:hover {
    color: black;
  }
}

.RightMenu {
  margin-right: 12px;
}

.LinkDelete {
  color: red;
  display: flex;
  justify-content: space-between;
}

.DeleteIcon {
  margin: auto 0;
}
