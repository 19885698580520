.Root {
  display: block;
  padding: 12px 24px 44px;
  overflow-y: scroll;
  flex-basis: 0;
  height: 90%;
}

.TableBtn {
  font-size: 12px;
}

.TableBtnDisabled {
  font-size: 12px;
  background-color: darkgray;
}

.ShowScopeOptionBtn {
  font-size: 14px;
  margin-left: 12px;
}

.SelectedTargets {
  margin-bottom: 1rem;
}

.SelectedTargetsTitle,
.AvailableTargetsTitle {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 10px;
}

.OperationTypeButtonGroup {
  display: flex;
  margin-bottom: 1rem;
}

.OperationTypeButton {
  flex: 1;

  &.OperationTypeButtonActive {
    background-color: #dbdcde;
  }

  &:not(:last-of-type) {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.Table {
  overflow-y: auto !important;
}
