.Root {
  display: flex;
  height: 100%;

  position: relative;
  flex-direction: column;
  background-color: white;
}

.Fieldset {
  padding: 0;
}

.StaticGroupActions {
  text-align: right;
  margin-bottom: 20px;
  margin-right: 20px;
}

.Button {
  margin-right: 20px;
}

.GroupTab {
  height: 100%;
  padding: 20px;
}

.Hidden {
  display: none;
}

.GroupTabInput {
  width: 60%;
}
