.Root {
  padding: 10px;
  width: 100%;
  tr td:first-child {
    width: 35%;
  }
  tr td, tr th {
    padding: 5px 10px;
    word-break: break-all;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
