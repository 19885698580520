.Root {
  display: block;
  overflow-y: auto;
  flex: 1;
}

.TopSection {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 20px;
}

.BackLink {
  padding: 0 20px;
}

.Step {
  margin: 20px;
}

.DeviceName {
  padding: 0 20px;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
