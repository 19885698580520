.Root {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-basis: 0;
  height: 90%;
}

.TopSection {
  text-align: left;
  margin: 10px 0;
  padding-left: 14px;
}

.TopSectionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.Table {
  overflow-y: auto !important;
}
