.HistoryTab {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  height: 100%;
  overflow: auto;
}

.HistoryDateFilter {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.HistoryDatePicker {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.DataTableContainer {
  overflow-y: scroll;
  width: 100%;
}
