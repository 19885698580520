.Root {
  height: 100%;
  padding: 20px;
}

.CellTextInput {
  height: 38px;
  margin: 0;
}

.CellValueDropdown {
  width: 100%;
  margin: 0;
}

.CellDropdown {
  margin: 0;
}

.AddCriteriaCellButton {
  font-size: 12px;
}

.ChooseCriteriaValueButton:disabled,
.ChooseCriteriaValueButton[disabled] {
  background-color: #e9ecef;
  opacity: 1;
  border: 1px solid #ced4da;
}
