.Link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: .5rem 1rem;
  margin-bottom: .2rem;
  border-radius: .25rem;

  &:focus, &:hover {
    background-color: rgba(255,255,255,.1);
    color: #fff;
  }
}

.LinkText {
  margin: 0;
}

.LinkIcon {
  width: 18px
}

.LinkActive {
  background-color: rgba(255,255,255,.15);
  color: #fff;

  &:focus, &:hover {
    background-color: rgba(255,255,255,.15);
    color: #fff;
  }
}
