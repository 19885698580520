.GeneralTable {
  padding: 10px;
  width: 100%;
  tr td:first-child {
    text-align: right;
    border-right: 1px solid #bfbfbf;
    width: 35%;
  }
  tr td {
    padding: 3px 10px;
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.ExtensionAttributeInput {
  margin: 0;
  padding: 0;
  width: 80%;
  :first-child {
    margin: 0;
    padding: 0;
    height: 30px;
    border: none;
    background: transparent;
  }
}
