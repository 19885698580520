.SettingsHeader {
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 12px;
  margin-left: 20px;
}

.SettingsTitle {
  margin: 0;
}

.LinkNew {
  margin-left: 20px;
}
