.Root {
  display: flex;
  height: 90%;
  position: relative;
  flex-direction: column;
  background-color: white;
}

.Options {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.OptionTabs {
  padding: 0;
  background-color: #f5f5f5;
  border-right: 1px solid #bfbfbf;
  margin: 0;
  position: absolute;
  width: 160px;
  height: 100vh;
}

.OptionTabItem {
  display: block;
  cursor: pointer;
  padding: 12px 24px;

  &.Active {
    background-color: white;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #e0e0e0;
  }
}

.OptionTabItemIcon {
  width: 15px;
  margin-right: 6px;
}

.OptionTabItemError {
  width: 15px;
  margin-left: 4px;

  path {
    fill: var(--color-red);
  }
}

.OptionTabText,
.OptionTabSubtext {
  margin: 0;
}

.OptionTabSubtext {
  font-size: 12px;
}

.OptionForm {
  flex: 1;
  flex-basis: 0;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
  position: relative;
  left: 160px;
}

.RawJson {
  display: block;
  padding: 16px;
  width: 70%;
}

.GroupTitle {
  margin-top: 20px;
  margin-bottom: 12px;

  &:first-of-type {
    margin-top: 0;
  }
}

.Textarea {
  height: 150px;

  &.Code {
    font-family: Consolas, monospace;
    height: 450px;
  }
}

.PolicyScope {
  flex: 1;
}

.PolicyActions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 24px;
  padding-top: 20px;
}

.ConfigProfileActionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.LogsTab {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  height: 100%;
  overflow: auto;
}

.DataTableContainer {
  overflow-y: auto !important;
}
