.Root {
  display: block;
  padding: 16px;
  width: 70%;
}

.GroupTitle {
  margin-top: 20px;
  margin-bottom: 12px;

  &:first-of-type {
    margin-top: 0;
  }
}
