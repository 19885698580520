.Root {
  display: flex;
  gap: 5px;
  padding: 10px;
  border-bottom: 1px solid #bfbfbf;
  white-space: nowrap;

  .CustomWrapper {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.Separator {
  margin-left: 5px;
}
