.Root {
  padding: 10px;
  width: 100%;
}

.ProfilesTable {
  width: 100%;

  tr td:first-child {
    word-break: normal;
  }
  tr td, tr th {
    padding: 5px 10px;
    word-break: break-all;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
