.InventoryTabNavbar {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
}

.DeviceName {
  color: black;
}

.Tabs {
  display: flex;
  justify-content: space-between;
  background-color: #bfbfbf;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  width: 100%;
}

.Tab {
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  display: block;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-right: 1px solid #bfbfbf;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    width: 16px;
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
    background-color: #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  &:not(:first-child):not(:last-child).Active {
    border-right: none;
    border-top: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    background-color: white;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
  }
  &:first-child.Active {
    border-right: none;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #bfbfbf;
    background-color: white;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
  }
  &:last-child.Active {
    border-right: none;
    border-top: 1px solid #bfbfbf;
    border-bottom: 1px solid #ffffff;
    background-color: white;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
  }
}

.CloseBtn {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #bfbfbf;
    cursor: pointer;
  }
}
