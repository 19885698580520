.Root {
  background-color: #e6e6e6;
  display: block;
  padding: 15px 0 0 0;
  transition: transform 0.5s ease;
  &.Collapsed {
    padding: 0;
  }
}

.ButtonContainer {
  margin: 15px auto 15px auto;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.CriterionContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0;
  align-items: center;
}

.Input {
  border: none;
  border-radius: 6px;
  padding: 5px;
  height: 32px;
}

.Hidden {
  display: none;
}

.ConjunctiveDropdown {
  min-width: 90px;
}

.CriterionDropdown {
  min-width: 140px;
}

.OperatorDropdown {
  min-width: 125px;
}

.BracketDropdown {
  min-width: 40px;
  padding: 5px 0;
  &.End {
    margin-right: 5px;
  }
  select {
    padding: 5px;
  }
}

.ValueInput {
  min-width: 140px;
  min-height: 48px;
  margin-bottom: 0;
}

.CollapseButton {
  width: 100%;
  height: 25px;
  background: #e9e9e9;
  color: #b0acac;
  border: none;
}
