.Sidebar {
  z-index: 1;
}

.Logo {
  opacity: 0.8;
  margin: 0 !important;
  transform: translateX(-50%);
  left: 50%;
}

.TabList {
  display: flex;
}

.Tab {
  flex: 1;
  text-align: center;
  a {
    color: white;
  }
}
