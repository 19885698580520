.Root {
  display: block;
  width: 75%;
  margin: 20px;
}

.TableActionCell {
  text-align: center;
}

.AddedPackageList {
}

.AddedPackageListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.AddedPackageListTitle {
  margin: 0;
}

.AddScriptCellButton {
  font-size: 12px;
}

.AddScriptButton {
  margin-top: auto;
  margin-bottom: auto;
}

.AddedPackage {
  padding: 20px 20px 16px 20px;
  margin: 20px 0;
  background-color: #fbfbfb;
}

.AddedPackageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.AddedPackageTitle,
.AddedPackageParametersTitle {
  font-size: 21px;
  font-weight: 500;
}

.AddedPackageParametersTitle {
  margin-bottom: 14px;
}
