.Root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  max-width: 70vh;
  min-width: 30vh;
  background-color: white;
  &:focus-visible {
    outline: none;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c2c2c2bf;
  z-index: 2;
}

.ModalBody {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-wrap: break-word;
  white-space: pre-line;
  hyphens: auto;
}

.Input {
  margin: 0;
}

.Error {
  color: var(--error-red);
  font-size: 14px;
}
