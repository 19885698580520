.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.Content {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
}
