.Root {
  display: flex;
  height: 90%;
  position: relative;
  flex-direction: column;
  background-color: white;
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Form {
  padding: 20px;
  width: 70%;
}

.Label {
  font-family: var(--primary-font);
  user-select: none;
  font-weight: 400;
  font-size: 16px;
  display: block;
}

.Description {
  font-size: 14px;
  font-weight: 200;
  user-select: none;
  display: block;
}

.Input {
  margin-bottom: 20px;
  display: flex;
  padding: 0;
  cursor: pointer;
}

.CheckboxLabel {
  padding-left: 7px;
  font-weight: 400;
  user-select: none;
}

.Checkbox {
  position: relative;
  margin: 0;
  padding: 0;
  height: 15px;
  cursor: pointer;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
  margin: 24px;
}

.Button {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.Scope {
  flex: 1;
}
