.Root {
  padding: 0 10px;
  background-color: #f5f5f5;
  min-width: 130px;
  width: min-content;
  height: 100%;
  display: flex;
  border-right: 1px solid #bfbfbf;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background-color: #e0e0e0;
  }
  &.Active {
    background-color: white;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.Value {
  width: 100%;
  text-align: center;
  line-height: 60px;
  color: #212529;

  &:active, &:hover {
    color: #212529;
  }
}
