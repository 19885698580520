.Root {
  padding: 0;
}

.Actions {
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}

.ActionItem {
  color: black;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 6px 0;

  &:hover {
    color: black;
  }
}

.TableBtn {
  font-size: 12px;
}

.ChangePasswordModalForm {
  display: flex;
  flex-direction: column;
}
