.ConfigProfilesContainer {
  flex: 1;
  position: relative;
  z-index: 0;
}

.Actions {
  display: flex;
  gap: 24px;
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}

.ButtonUpload {
  display: flex;
  align-items: center;
  gap: 10px;
}
