.Root {
  padding: 0;
}

.SelectCreateTypeBlock {
  padding: 20px;
}

.SelectCreateTypeBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
