.Root {
  width: 400px;
}

.Fieldset {
  padding: 0;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

.Breadcrumbs {
  background-color: transparent;
}

.Content {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
}

.Actions {
  padding-bottom: 10px;
}

.Error {
  color: var(--error-red);
  font-size: 14px;
}

.LicensesListContainer {
  padding-top: 20px;
  flex: 1;
  position: relative;
  z-index: 0;
}

.Email {
  color: var(--error-red);
}

.FileInput {
  width: 60%;
  overflow: hidden;
}

.Textarea {
  width: 50%;
  min-height: 180px;
  font-family: Consolas, monospace;
}
