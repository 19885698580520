.Root {
  padding: 0;
}

.Actions {
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}

.ActionItem {
  color: black;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 6px 0;

  &:hover {
    color: black;
  }
}

.PolicyIconDisabled {
  color: #6c757d;
}

.PolicyIconHasErrors {
  color: #dc3545;
}

.PolicyIconEnabled {
  color: #28a745;
}
