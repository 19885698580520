.Root {
  padding: 0;
}

.CommandsTab {
  padding: 10px;
  width: 100%;
  tr td:first-child {
    text-align: right;
    border-right: 1px solid #bfbfbf;
    width: 35%;
  }
  tr td {
    padding: 3px 10px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.ManagementCommandsGrid {
  margin-bottom: 15px;
}

.CommandBtn {
  height: 40px;
  margin: 5px;
  border: none;
  background: #d9d9d9;
  font-size: 16px;
  padding-left: 20px;
  display: block;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    width: 20px;
    margin-right: 5px;
  }
  &:hover {
    background: #f2f2f2;
  }
}

.Placeholder {
  height: 40px;
  margin: 5px;
  padding-left: 20px;
}

.CommandsTabTable {
  width: 100%;
  margin-bottom: 15px;
  thead {
    background: #d9d9d9;
    th {
      &:first-child {
        width: 60%;
      }
      &:not(:first-child) {
        width: 20%;
      }
    }
  }
  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.CommandForm {
  width: 100%;
}

.ModalText {
  color: black;
}
