.Root {
  display: block;
  width: 75%;
  margin: 20px;
  overflow-y: scroll;
}

.TableActionCell {
  text-align: center;
}

.AddedScriptList {
}

.AddedScriptListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.AddedScriptListTitle {
  margin: 0;
}

.AddScriptCellButton {
  font-size: 12px;
}

.AddScriptButton {
  margin-top: auto;
  margin-bottom: auto;
}

.AddedScript {
  padding: 20px 20px 16px 20px;
  margin: 20px 0;
  background-color: #fbfbfb;
}

.AddedScriptHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.AddedScriptTitle,
.AddedScriptParametersTitle {
  font-size: 21px;
  font-weight: 500;
}

.AddedScriptParametersDisclaimer {
  margin-bottom: 10px;
  color: #b6b6b6;
}

.AddedScriptParametersTitle {
  margin-bottom: 14px;
}
