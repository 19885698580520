@use '../assets/styles/main.scss' as *;

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
}

.Header {
  position: sticky;
  top: 0;
  z-index: 100;
}

.Body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.Content {
  overflow: auto;
  height: 100%;
}

.Footer {
}
