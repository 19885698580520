.MainHeader {
  z-index: 1;
}

.MainHeaderContainer {
  min-width: 100%;
}

.Username {
  margin-right: 8px;
}

.NavLink {
  padding-left: 10px !important;
  padding-right: 12px !important;
}
