.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.NotConfiguredContainer {
  margin: 20px;
}


.Form {
  margin: 0 20px 20px;
  width: 60%;
  min-width: 200px;
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Breadcrumbs {
  background-color: transparent;
}

.Content {
  margin: 20px 20px 0;
}

.Fieldset {
  padding: 0;
}

.TestConnectionContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.TestConnectionResult {
  margin-left: 10px;
}

.TestConnectionSuccess {
  color: var(--success-green);
}

.TestConnectionFailure {
  color: var(--error-red);
 }

.InputPassword {
  margin-bottom: 0;
}

.EditPasswordBtn {
  display: inline-block;
  margin: 4px 0 0;
}

.ShowMappingsBtn {
  display: inline-block;
  margin: 8px 0 0;
}

.EditPasswordBtn {
  display: inline-block;
  margin: 4px 0 0;
}

.EditCaCertificateBtn {
  display: block;
  margin-bottom: 8px;
}

.HasCertificate {
  margin-bottom: 8px;
}

.HasCertificateValue {
  font-weight: bold;
}

.ActionBtnGroup {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.Mappings {
  margin-top: 20px;
}

.ServerError {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.Success {
  font-size: 14px;
  font-weight: 400;
  color: var(--success-green);
}
