.Root {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  &.InsideModal {
    margin: 0;
  }
}

.Label {
  font-family: var(--primary-font);
  user-select: none;
  font-weight: 400;
  margin: 0;
}

.Input {
  flex: 1;
  resize: inherit;
  font-family: inherit;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}

.DisplayOnly {
  margin: 0;
  border-color: #dae0e7;
}

.Error {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
  &.InsideModal {
    height: 21px;
  }
}

.Required {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
  margin-left: 4px;
}

.PasswordEye {
  position: absolute;
  overflow: hidden;
  bottom: 3px;
  right: 8px;
  cursor: pointer;
  color: lightgray;
  user-select: none;
}
