.Root {
  padding: 0;
  width: 100%;
}

.Logo {
  width: 28px;
  opacity: 0.8;
  margin: 0 !important;
  transform: translateX(-50%);
  left: 50%;
}

.Button {
  font-size: 14px;
  white-space: nowrap;
}

.OperationTypeButtonGroup {
  display: flex;
}

.OperationTypeButton {
  flex: 1;

  &.OperationTypeButtonActive {
    background-color: #dbdcde;
  }

  &:not(:last-of-type) {
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
  }

  &:not(:first-of-type) {
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
  }
}
