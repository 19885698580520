.Root {
  height: 100%;
  padding: 20px;
}

.Fieldset {
  padding: 0;
}

.FormTitle {
  margin-bottom: 20px;
}

.Input {
  width: 60%;
}

.Textarea {
  height: 150px;

  &.Code {
    font-family: Consolas, monospace;
    height: 300px;
  }
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
}
