.Body {
  display: flex;
  width: 100%;
}

.Sidebar {
  flex: 0 0 20%;
  max-width: 20%;
  border-right: solid 1px var(--color-border-secondary);
  padding: 10px 8px;
  background-color: #f5f5f5;
  border-right: 1px solid #bfbfbf;
  margin: 0;
  min-height: 90vh;
}

.List {
  padding-left: 30px;
  margin: 0;
}

.Content {
  flex: 0 0 80%;
  flex-flow: column nowrap;
}

.Tab {
  margin: 15px 0;
}

.TabHighlight {
  font-weight: 600;
}

.ActionButton {
  display: flex;
  justify-content: flex-end;
  margin: 24px;
  padding: 20px;
}

.Button {
  margin: 5px;
}
