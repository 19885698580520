.Root {
  flex: 1;
  width: 50%;
  max-width: 500px;
  margin: 10% auto;
  background-color: #f1f1f1;
  border-radius: 5px;
  max-height: 290px;
}

.Form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.FormGroup {
  height: 94px;
  margin-bottom: 0;
}

.FormFooter {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  flex: 0 0 auto;
}

.passwordContainer {
  width: 100%;
  position: relative;
}

.passwordEye {
  position: absolute;
  top: 22%;
  right: 3%;
  cursor: pointer;
  color: lightgray;
  user-select: none;
}

.Error {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

@media (max-width: 900px) {
    .Root {
      min-height: 310px;
    }
  .Error {
    font-size: 12px;
  }
}
