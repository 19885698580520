.InventoryContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.DataTableContainer {
  overflow-y: scroll;
}

.InventoryActions {
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
