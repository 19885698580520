.Root {
  height: 95%;
}

.Form {
  display: flex;
  flex-direction: column;
  margin: 20px;
  height: 95%;
}

.Fieldset {
  padding: 0;
}

.Input {
  width: 60%;
  min-width: 200px;
}

.ActionBtnGroup {
  margin-top: auto;
  text-align: right;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.Title {
  margin-bottom: 20px;
}

.ServerError {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
  vertical-align: middle;
  margin-right: 14px;
}
