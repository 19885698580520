.Root {
  padding: 20px;
}

.Label {
  padding-left: 7px;
  font-weight: 400;
  user-select: none;
}

.LabelHighlight {
  font-weight: 600;
}

.Input {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.Checkbox {
  position: relative;
  margin: 0;
  padding: 0;
  height: 15px;
  cursor: pointer;
}
