.Root {
  padding: 16px;
}

.BuiltInCaInfo {
  padding: 16px;
  background-color: #e0e0e0;
  margin-bottom: 16px;
}

.BuiltInCaInfoSubject {
  font-weight: bold;
}

.ActionButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.CaBackupInput {
  flex-direction: column;
}
