.Header {
  display: flex;
  justify-content: space-between;
}

.HeaderContent {
  flex-grow: 1;
  text-align: left;
}

.HeaderIcon {
  flex-shrink: 1;
  transition: transform 0.3s;
}

.HeaderIconFlipped {
  transform: rotateX(180deg);
}

.Body {
  transition: max-height 0.3s , padding-bottom 0.4s, padding-top 0.4s;
  transition-timing-function: ease;
  max-height: 400px;
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: 16px;
}

.BodyCollapsed {
  max-height: 0;
  min-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
