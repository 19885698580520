.Root {
  padding: 10px;
  width: 100%;
  tr td:first-child {
    text-align: right;
    border-right: 1px solid #bfbfbf;
    width: 35%;
  }
  tr td {
    padding: 3px 10px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
