.CodeName {
  font-weight: 600;
}

.Hidden {
  display: none;
}

.Error {
  margin-top: 5px;
  color: var(--error-red);
}