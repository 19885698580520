.Root {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.SettingsNavbar {
  border-top: 1px solid #bfbfbf;
}

.Tab {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 150px;
  grid-gap: 12px;
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
}

.SettingsCell {
  min-width: 0;
}

.SettingsCard {
  height: 100%;

  color: black;
  &:hover {
    color: black;
    background-color: #e0e0e0;
  }
}

.CardHeader {
  white-space: nowrap;
}
