.Root {
  height: 60px;
  min-height: 60px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 1px solid #bfbfbf;
}

.Tabs {
  display: flex;
  justify-content: space-between;
  background-color: #bfbfbf;
  list-style-type: none;
  height: 100%;
  padding: 0;
  margin: 0;
  &:first-child {
    border-top-left-radius: 3px;
  }
}
