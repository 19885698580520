.Root {
  padding: 10px;
  width: 100%;
}

.ProvisioningProfilesTable {
  width: 100%;
  table-layout: fixed;
  tr td,
  tr th {
    padding: 5px 10px;
    border-right: 1px solid #bfbfbf;
    word-break: break-all;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: none;
  }
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
