.Root {
  padding: 20px;
}

.Label {
  font-family: var(--primary-font);
  user-select: none;
  font-weight: 400;
  margin: 0;
}

.FilterInput {
  width: 300px;
  display: block;
}

.DropdownBlock {
  display: inline-block;
}

.CountryDropdown {
  display: block;
  width: 260px;
  margin: 0;
}

.Input {
  border: none;
  border-radius: 6px;
  padding: 5px;
  height: 32px;
}
