.Root {
  display: block;
}

.Label {
  font-weight: 400;
  user-select: none;
  margin-bottom: 0;
}

.SubLabel {
  color: grey;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
  margin-bottom: 0;
}

.InputGroup {
  cursor: pointer;
}

.FileInput {
  display: none;
}

.Error {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.FileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
