.Root {
  height: 100%;
  padding: 20px;
}

.Filter {
  width: 100%;
  padding: 10px;
}

.FilterInput {
  width: 300px;
  margin: 0 20px 0 0;
  vertical-align: middle;
}

.FilterClearButton {
  width: 100px;
}

.SelectCellLabel {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0 15px;
  cursor: pointer;
  z-index: 1;
}

.SelectCellLabelDisabled {
  cursor: default;
}

.SelectCellInput {
  cursor: inherit;
}
