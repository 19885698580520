.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px;
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Breadcrumbs {
  background-color: transparent;
}

.Content {
  margin: 30px 20px 0;
}

.Fieldset {
  padding: 0;
}

.Status {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
}

.ActionBtnGroup {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.ServerError {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.Success {
  font-size: 14px;
  font-weight: 400;
  color: var(--success-green);
}
