.Root {
  display: flex;
  height: 100%;

  position: relative;
  flex-direction: column;
  background-color: white;
}

.Hidden {
  display: none;
}

.Fieldset {
  padding: 0;
}

.SmartGroupActions {
  text-align: right;
  margin-bottom: 20px;
  margin-right: 20px;
}

.SmartGroupActionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
