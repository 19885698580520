.Root {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  height: 90%;
  overflow: auto;
}

.DataTableContainer {
  overflow-y: scroll;
  width: 100%;
}

.Table {
  overflow-y: auto !important;
}
