.Root {
  display: block;
  padding: 16px;
  width: 100%;
}

.GroupTitle {
  margin-top: 20px;
  margin-bottom: 12px;

  &:first-of-type {
    margin-top: 0;
  }
}

.Tabs {
  display: flex;
}

.TabItem {
  padding: 10px;
}

.Options {
  display: flex;
}

.OptionsTabs {
  flex-basis: 200px;
}

.OptionTabItem {
}

.OptionForm {
  flex: 1;
  height: 100%;
}

.ExtraFormInput {
  margin-left: 20px;
  margin-bottom: 10px;

  &.Hidden {
    display: none;
  }
}

.ImportedTeg {
  padding-bottom: 15px;
}

.Input {
  width: 60%;
}
