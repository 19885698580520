.Root {
  padding: 10px;
  width: 100%;
}

.Input {
  width: 50%;
  margin: 0 20px 0 0;
}

.SearchBar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.SearchButton {
  margin-left: 15px;
}

.DeviceUserForm {
  padding: 0;
}
